import { RootState } from '@store/types';

export const fetchAllClientIsLoadingSelector = (state: RootState) => state.clients.fetchAllClientIsLoading;
export const clientListSelector = (state: RootState) => state.clients.clients.data;
export const totalNumberOfClientPagesSelector = (state: RootState) => state.clients.clients.totalPages;
export const fetchAllClientHasErrorSelector = (state: RootState) => state.clients.fetchAllClientHasError;

export const availablePortalStatusIsLoadingSelector = (state: RootState) =>
  state.clients.availablePortalStatusIsLoading;
export const availablePortalStatusSelector = (state: RootState) => state.clients.availablePortalStatus;
export const availablePortalStatusHasErrorSelector = (state: RootState) => state.clients.availablePortalStatusHasError;

export const updateClientPortalIsLoadingSelector = (state: RootState) => state.clients.updateClientPortalIsLoading;
export const updateClientPortalIsSuccessSelector = (state: RootState) => state.clients.updateClientPortalIsSuccess;
export const updateClientPortalHasErrorSelector = (state: RootState) => state.clients.updateClientPortalHasError;

export const availableTemplatesIsLoadingSelector = (state: RootState) => state.clients.availableTemplatesIsLoading;
export const availableTemplatesSelector = (state: RootState) => state.clients.availableTemplates;
export const availableTemplatesHasErrorSelector = (state: RootState) => state.clients.availableTemplatesHasError;

export const updateClientTemplateIsLoadingSelector = (state: RootState) => state.clients.updateClientTemplateIsLoading;
export const updateClientTemplateIsSuccessSelector = (state: RootState) => state.clients.updateClientTemplateIsSuccess;
export const updateClientTemplateHasErrorSelector = (state: RootState) => state.clients.updateClientTemplateHasError;

export const chosenStyleIsLoadingSelector = (state: RootState) => state.clients.chosenStyleIsLoading;
export const chosenStyleSelector = (state: RootState) => state.clients.chosenStyle;
export const chosenStyleHasErrorSelector = (state: RootState) => state.clients.chosenStyleHasError;

export const materialsIsLoadingSelector = (state: RootState) => state.clients.materialsIsLoading;
export const materialsSelector = (state: RootState) => state.clients.materials;
export const materialsHasErrorSelector = (state: RootState) => state.clients.materialsHasError;

export const materialColorIsLoadingSelector = (state: RootState) => state.clients.materialColorIsLoading;

export const editChosenStyleIsLoadingSelector = (state: RootState) => state.clients.editChosenStyleIsLoading;
export const editChosenStyleIsSuccessSelector = (state: RootState) => state.clients.editChosenStyleIsSuccess;
export const editChosenStyleHasErrorSelector = (state: RootState) => state.clients.editChosenStyleHasError;

export const createQuoteIsLoadingSelector = (state: RootState) => state.clients.createQuoteIsLoading;
export const createQuoteIsSuccessSelector = (state: RootState) => state.clients.createQuoteIsSuccess;
export const createQuoteHasErrorSelector = (state: RootState) => state.clients.createQuoteHasError;

export const fetchExistingTemplatesIsLoadingSelector = (state: RootState) =>
  state.clients.fetchExistingTemplatesIsLoading;

export const exitingTemplateDataSelector = (state: RootState) => state.clients.existingTemplateData;

export const fetchAppointmentDetailsLoadingSelector = (state: RootState) =>
  state.clients.fetchAppointmentDetailsLoading;
export const appointmentDetailsSelector = (state: RootState) => state.clients.appointmentDetails;
export const fetchAppointmentDetailsHasErrorSelector = (state: RootState) =>
  state.clients.fetchAppointmentDetailsHasError;

export const masterColorDropDownLoadingSelector = (state: RootState) => state.clients.masterColorDropDownLoading;
export const masterColorDropDownDataSelector = (state: RootState) => state.clients.masterColorDropDownData;
export const masterColorDropDownHasErrorSelector = (state: RootState) => state.clients.masterColorDropDownHasError;

export const productTypeDropDownLoadingSelector = (state: RootState) => state.clients.productTypeDropDownLoading;
export const productTypeDropDownDataSelector = (state: RootState) => state.clients.productTypeDropDownData;
export const productTypeDropDownHasErrorSelector = (state: RootState) => state.clients.productTypeDropDownHasError;
export const fetchClientQuoteSelector = (state: RootState) => state.clients.getClientQuote;
export const fetchClientQuoteSuccessSelector = (state: RootState) => state.clients.fetchClientQuoteSuccess;
export const sendQuoteIsSuccessSelector = (state: RootState) => state.clients.sendQuoteIsSuccess;
export const uploadEditTimeSlotIsSuccessSelector = (state: RootState) => state.clients.uploadEditTimeSlotIsSuccess;
export const uploadedTimeSlotAlreadyExistSelector = (state: RootState) => state.clients.uploadedTimeSlotAlreadyExist;
export const deleteTimeSlotIsSuccessSelector = (state: RootState) => state.clients.deleteTimeSlotIsSuccess;
export const postChosenStylesIsSuccessSelector = (state: RootState) => state.clients.postChosenStylesIsSuccess;
export const productTyoeDropDownIsEmptySelector = (state: RootState) => state.clients.productTyoeDropDownIsEmpty;
export const existingTemplateDataSelector = (state: RootState) => state.clients.existingTemplateData;
export const deleteTemplateWindowIsSuccessSelector = (state: RootState) => state.clients.deleteTemplateWindowIsSuccess;
export const selectTemplateStatusChangedSelector = (state: RootState) => state.clients.selectTemplateStatusChanged;
export const floorMaterialPriceSelector = (state: RootState) => state.clients.floorMaterialPrice;
export const windowMaterialPriceSelector = (state: RootState) => state.clients.windowMaterialPrice;
export const curtainMaterialPriceSelector = (state: RootState) => state.clients.curtainMaterialPrice;
export const selectedLotTypeIdSelector = (state: RootState) => state.clients.selectedLotTypeId;
export const selectedProjectIdSelector = (state: RootState) => state.clients.selectedProjectId;

export const inviteAllClientsSuccessSelector = (state: RootState) => state.clients.inviteAllClientsSuccess;
export const inviteSingleClientsSuccessSelector = (state: RootState) => state.clients.inviteClientsSuccess;
export const inviteSingleClientToStyleroomSuccessSelector = (state: RootState) =>
  state.clients.inviteClientToStyleroomSuccess;
