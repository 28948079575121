import React from 'react';
import Card from '@mui/material/Card';

import { Client } from '../../types';
import { styled } from '@mui/material/styles';

const Label = styled('p')`
  font-size: 16px;
  font-weight: 700;
  color: #343e47;
`;

const Value = styled('p')`
  font-size: 16px;
  font-weight: 300;
  color: #343e47;
`;

interface ContactDetailsCardProps {
  selectedClient: Client;
}

function ContactDetailsCard(props: ContactDetailsCardProps) {
  const { selectedClient } = props;
  const data = [
    {
      label: 'Naam',
      value: selectedClient?.client,
    },
    {
      label: 'E-mail',
      value: selectedClient?.email,
    },
    {
      label: 'Telefoon',
      value: selectedClient?.teliphone,
    },
    {
      label: 'Mobiel',
      value: selectedClient?.mobile,
    },
  ];

  return (
    <Card
      style={{
        padding: '20px 0px 0px 12px',
        boxShadow: '0px 10px 24px 0px rgba(12, 27, 42, 0.06)',
        height: '90%',
      }}
    >
      {data.map((item, index) => (
        <div key={index} style={{ paddingBottom: '15px' }}>
          {item.value && <Label>{item.label}</Label>}
          <Value>{item.value}</Value>
        </div>
      ))}
    </Card>
  );
}

export default ContactDetailsCard;
