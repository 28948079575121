import { CustomTableDataCell } from '@shared/components/customTable/CustomTable';
import React from 'react';
import { TableBodyRow } from '@shared/types';
import { LotType } from '../types';
import { IconButton } from '@mui/material';
import { ReactComponent as AddPriceIcon } from '@assets/images/tableRowIcons/addPrice.svg';
import { ReactComponent as AddTemplateIcon } from '@assets/images/tableRowIcons/addTemplate.svg';
import LightTooltip from '../../../utils/lightTooltip';

export default function lotTypeTableData(
  lotTypeList: LotType[],
  tableHeaderWidth: (string | undefined)[],
  handleMaterialOverviewClick: (a: LotType) => void,
  handleAddTemplateClick: (a: LotType) => void,
  handleClickOnRow: (lotTypeId: number) => void,
  showFilter: boolean
): TableBodyRow[] {
  return lotTypeList.map((lotType: LotType) => {
    return {
      id: `${lotType.idLotType}`,
      tableCell: (
        <>
          <CustomTableDataCell
            width={tableHeaderWidth[0] ?? 'unset'}
            fontWeight={700}
            onClick={() => handleClickOnRow(lotType.idLotType)}
            clickable
          >
            {lotType.lotType1}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700} justify="center">
            <div style={{ paddingLeft: showFilter ? '65px' : '55px' }}>{lotType.styledLots}</div>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[2] ?? 'unset'} fontWeight={700} justify="center">
            <div style={{ paddingLeft: showFilter ? '40px' : '28px' }}>{lotType.totalMeter}</div>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[3] ?? 'unset'} fontWeight={700} justify="center">
            <div style={{ paddingLeft: showFilter ? '33px' : '35px' }}>{lotType.floors}</div>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[5] ?? 'unset'}>
            <LightTooltip title="Prijs bewerken">
              <IconButton
                disabled={!lotType.floors}
                className={'table-icon'}
                onClick={() => handleMaterialOverviewClick(lotType)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <AddPriceIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[6] ?? 'unset'}>
            <LightTooltip title="Toevoegen template">
              <IconButton
                disabled={!lotType.floors}
                className={'table-icon'}
                onClick={() => handleAddTemplateClick(lotType)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <AddTemplateIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
        </>
      ),
    };
  });
}
