import React, { useCallback, useEffect, useState } from 'react';

import CommonHeader from '@shared/components/commonHeader/CommonHeader';
import TableSearchBar from '@shared/components/tableSearchBar/TableSearchBar';
import PageLayout from '@layout/PageLayout';

import { ReactComponent as AddUserIcon } from '../../../../assets/images/addUser.svg';
import { Paper } from '@mui/material';
import CustomTable from '@shared/components/customTable/CustomTable';
import { TableColumnSortDirection } from '@shared/types';
import { Client } from '@clients/types';
import MaterialOverviewModal from '../../projects/components/MaterialOverviewModal';
import CreateEditTemplateModal from '../components/CreateEditTemplateModal';
import EditPortalModal from '@clients/components/EditPortalModal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@store/types';
import {
  clientListSelector,
  fetchAllClientIsLoadingSelector,
  totalNumberOfClientPagesSelector,
  inviteAllClientsSuccessSelector,
} from '@clients/store/selectors/clientSelector';
import { clientActions } from '@clients/store/slice/clientSlice';
import clientTableData from '@clients/components/clientTableData';
import clientTableHeaders from '@clients/components/clientTableHeaders';
import { useNavigate, useParams } from 'react-router-dom';
import GoBackButton from '../../../shared/components/goBackButton/GoBackButton';
import { ROUTE_CLIENTS, ROUTE_PROJECTS } from '../../../utils/routes';
import { projectActions } from '../../projects/store/slice/projectsSlice';
import { projectsSelector } from '../../projects/store/selectors/projectsSelectors';

function ClientsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId: selectedProjectId, idLotType: selectedLotTypeId } = useParams();

  const clientData = useAppSelector(clientListSelector);
  const fetchAllClientIsLoading = useAppSelector(fetchAllClientIsLoadingSelector);
  const totalNumberOfPages = useAppSelector(totalNumberOfClientPagesSelector);
  const inviteAllClientsSuccess = useAppSelector(inviteAllClientsSuccessSelector);

  const projects = useAppSelector(projectsSelector);
  const selectedProject = (projects || []).find((i) => `${i.idProject}` === selectedProjectId);

  const [selectedClient, setSelectedClient] = React.useState<Client | null>();
  const [openMaterialOverviewModal, setOpenMaterialOverviewModal] = React.useState(false);
  const [openEditTemplateModal, setOpenEditTemplateModal] = React.useState(false);
  const [openEditPortalModal, setOpenEditPortalModal] = React.useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState<string | undefined>('processcode');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [lotTypeId, setLotTypeId] = useState<number | null>(null);
  const [searchKeyFilter, setSearchKeyFilter] = useState<string>();
  const [projectFilter, setProjectFilter] = useState<string | undefined>();
  const [lotFilter, setLotFilter] = useState<string | undefined>();
  const [clientFilter, setClientFilter] = useState<string | undefined>();
  const [statusFilter, setStatusFilter] = useState<string | undefined>();
  const [floorFilter, setFloorFilter] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (selectedLotTypeId) {
      dispatch(projectActions.fetchProjects({ pageSize: 1000 }));
    }
  }, [dispatch, selectedLotTypeId]);

  useEffect(() => {
    if (selectedLotTypeId) {
      setLotTypeId(parseInt(selectedLotTypeId));
    }
  }, [selectedLotTypeId]);

  function toggleFilters() {
    setShowFilters((prevVal) => !prevVal);
  }

  const fetchClients = useCallback(() => {
    dispatch(
      clientActions.fetchAllClients({
        lotTypeId: lotTypeId,
        searchKey: searchKeyFilter,
        lots: lotFilter,
        clients: clientFilter,
        projectname: projectFilter,
        status: statusFilter,
        floors: floorFilter,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
        pageNumber: searchKeyFilter
          ? 1
          : !lotFilter && !clientFilter && !projectFilter && !statusFilter && !floorFilter
          ? currentPage
          : 1,
      })
    );
  }, [
    dispatch,
    lotTypeId,
    searchKeyFilter,
    lotFilter,
    clientFilter,
    projectFilter,
    statusFilter,
    floorFilter,
    sortBy,
    sortDirection,
    currentPage,
  ]);

  useEffect(() => {
    if (inviteAllClientsSuccess === true) {
      fetchClients();
    }
  }, [inviteAllClientsSuccess, fetchClients]);

  useEffect(() => {
    dispatch(clientActions.fetchClientStatus());
    fetchClients();
  }, [
    lotTypeId,
    searchKeyFilter,
    lotFilter,
    clientFilter,
    projectFilter,
    statusFilter,
    floorFilter,
    currentPage,
    dispatch,
    sortDirection,
    sortBy,
  ]);

  function handleMaterialOverviewClick(client: Client) {
    setSelectedClient(client);
    setOpenMaterialOverviewModal(true);
  }

  function handleEditTemplateClick(client: Client) {
    setSelectedClient(client);
    setOpenEditTemplateModal(true);
  }

  function handleEditPortalClick(client: Client) {
    setSelectedClient(client);
    setOpenEditPortalModal(true);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  function handleCloseMaterialOverviewModal() {
    setOpenMaterialOverviewModal(false);
    setSelectedClient(null);
  }

  function handleCloseEditTemplateModal() {
    setOpenEditTemplateModal(false);
    setSelectedClient(null);
  }

  function handleCloseEditPortalModal() {
    setOpenEditPortalModal(false);
    setSelectedClient(null);
  }

  function handleClickOnRow(selectedClientId: number, selectedLotsId: number) {
    if (lotTypeId && selectedProjectId) {
      dispatch(clientActions.setSelectedLotTypeId({ lotTypeId: lotTypeId, projectId: selectedProjectId }));
    } else {
      dispatch(clientActions.setSelectedLotTypeId(null));
    }
    navigate(`${ROUTE_CLIENTS}/${selectedClientId}/${selectedLotsId}`);
  }

  function handleInviteAll() {
    if (lotTypeId) {
      dispatch(clientActions.inviteAllClients(lotTypeId));
    } else {
      dispatch(clientActions.inviteAllClients(null));
    }
  }

  const tableHeaders = clientTableHeaders(
    lotFilter,
    setLotFilter,
    clientFilter,
    setClientFilter,
    projectFilter,
    setProjectFilter,
    statusFilter,
    setStatusFilter,
    floorFilter,
    setFloorFilter
  );

  const tableData = clientTableData(
    clientData,
    tableHeaders.map((i) => i.width),
    handleMaterialOverviewClick,
    handleEditTemplateClick,
    handleEditPortalClick,
    handleClickOnRow
  );

  return (
    <PageLayout>
      {selectedLotTypeId ? (
        <CommonHeader header={`Clients for project ‘${selectedProject?.projectName}’`}>
          <GoBackButton onClick={() => navigate(`${ROUTE_PROJECTS}/${selectedProjectId}/lotTypes`)} />
        </CommonHeader>
      ) : (
        <CommonHeader header={t('clients.title')} subHeader={t('clients.description')} />
      )}
      <Paper className="common-paper">
        <TableSearchBar
          buttonStartIcon={<AddUserIcon />}
          buttonText={'Nieuwe klanten uitnodigen'}
          buttonClickHandler={handleInviteAll}
          placeHolder={'zoekclient of kavels...'}
          showFilterToggle
          toggleFilterHandler={toggleFilters}
          onSearch={setSearchKeyFilter}
          showFilter={showFilters}
        />
        <CustomTable
          tableHeaders={tableHeaders}
          showFilters={showFilters}
          tableData={tableData}
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          isLoading={fetchAllClientIsLoading}
        />
      </Paper>
      <MaterialOverviewModal
        openModal={openMaterialOverviewModal}
        closeModal={handleCloseMaterialOverviewModal}
        idLot={selectedClient?.idLots}
        floors={selectedClient?.floors}
        refreshClients={fetchClients}
        title={selectedClient?.client}
      />
      <CreateEditTemplateModal
        openModal={openEditTemplateModal}
        closeModal={handleCloseEditTemplateModal}
        idLot={selectedClient?.idLots}
        floors={selectedClient?.floors}
        refreshClients={fetchClients}
        selectedClientName={selectedClient?.client}
        floorImageUploadAvailable
      />
      <EditPortalModal
        openModal={openEditPortalModal}
        closeModal={handleCloseEditPortalModal}
        selectedClient={selectedClient!}
        refreshClients={fetchClients}
      />
    </PageLayout>
  );
}

export default ClientsPage;
