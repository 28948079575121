import React from 'react';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import { PostInviteClient, PostInviteClientToStyleroom } from '../../types';
import TableStatusLabel, { TableStatusType } from '../../../../shared/components/tableTextOptions/TableStatusLabel';
import { clientActions } from '../../store/slice/clientSlice';

import { styled } from '@mui/material/styles';

const Label = styled('p')`
  font-size: 16px;
  font-weight: 700;
  color: #343e47;
`;

const Value = styled('p')`
  font-size: 16px;
  font-weight: 300;
  color: #343e47;
`;

const InviteButton = styled('button')`
  text-transform: uppercase;
  background-color: #bd8890;
  border: none;
  border-radius: 5px;
  color: #fffffe;
  padding: 8px 10px;
  font-family: Public-sans;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

interface ProjectDetailsCardProps {
  name: string;
  floors: number | null;
  status: string;
  code: number | null;
  selectedClientId: number | null;
}

function ProjectDetailsCard(props: ProjectDetailsCardProps) {
  const dispatch = useDispatch();

  const { name, floors, status, code, selectedClientId } = props;
  const showInviteClient = status.includes('Nodig klant uit voor app');
  const showInviteClientToStyleroom = status.includes('afspraak bevestigd');

  function handleTableStatus(colorCode: string) {
    switch (colorCode) {
      case '1':
        return TableStatusType.Error;
      case '2':
        return TableStatusType.Warn;
      case '3':
        return TableStatusType.Success;
      default:
        return undefined;
    }
  }

  function handleInviteUser() {
    const payload: PostInviteClient = {
      id: selectedClientId,
    };
    dispatch(clientActions.inviteClient(payload));
  }

  function handleInviteUserToStyleroom() {
    const payload: PostInviteClient = {
      id: selectedClientId,
    };
    dispatch(clientActions.inviteClientToStyleroom(payload));
  }

  return (
    <Card style={{ padding: '20px 12px', boxShadow: '0px 10px 24px 0px rgba(12, 27, 42, 0.06)', height: '90%' }}>
      <div style={{ marginBottom: '15px' }}>
        <Label>Naam</Label>
        <Value>{name}</Value>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Label>Aantal verdiepingen</Label>
        <Value>{floors}</Value>
      </div>
      <div>
        <Label style={{ marginBottom: '10px' }}>Portaal status</Label>
        <TableStatusLabel label={status} type={handleTableStatus(`${code}`)} />
      </div>
      <div style={{ marginBottom: '15px' }}>
        {showInviteClient && (
          <InviteButton disabled={!showInviteClient} onClick={handleInviteUser}>
            Gebruiker uitnodigen
          </InviteButton>
        )}
      </div>
      <div>
        {showInviteClientToStyleroom && (
          <InviteButton disabled={!showInviteClientToStyleroom} onClick={handleInviteUserToStyleroom}>
            Invite To Styleroom
          </InviteButton>
        )}
      </div>
    </Card>
  );
}

export default ProjectDetailsCard;
