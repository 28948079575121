import { takeEvery, takeLatest } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import callFetchAllClientsSaga from './callFetchAllClientsSaga';
import callUpdateClientPortalSaga from './callUpdateClientPortalSaga';
import callUpdateClientTemplateSaga from './callUpdateClientTemplateSaga';
import callFetchAvailableTemplatesSaga from './callFetchAvailableTemplatesSaga';
import callFetchAvailablePortalStatusSaga from './callFetchAvailablePortalStatusSaga';
import callFetchChosenStyleSaga from './callFetchChosenStyleSaga';
import callFetchMaterialsSaga from './callFetchMaterialsSaga';
import callFetchMaterialColorSaga from './callFetchMaterialColorSaga';
import callUpdateChosenStyleSaga from './callUpdateChosenStyleSaga';
import callInviteAllClientsSaga from './callInviteAllClientsSaga';
import callFetchExistingTemplatesSaga from './callFetchExistingTemplatesSaga';
import callCreateQuoteSaga from './callCreateQuoteSaga';
import callFetchAppointmentDetailsSaga from './callFetchAppointmentDetailsSaga';
import callFetchMasterColorDropDownSaga from './callFetchMasterColorDropDown';
import callFetchProductTypeDropDownSaga from './callFetchProductTypeDropDown';
import callFetchClientQuoteSaga from './callFetchClientQuoteSaga';
import callSendClientQuoteSaga from './callSendClientQuoteSaga';
import callUploadEditTimeSlotSaga from './callUploadEditTimeSlotSaga';
import callDeleteTimeSlotSaga from './callDeleteTimeSlotSaga';
import callPostChosenStylesSaga from './callPostChosenStylesSaga';
import callFetchClientStatusSaga from './callFetchClientStatusSaga';
import callDeleteWindowSaga from './callDeleteWindowSaga';
import callFetchFloorMaterialPriceSaga from './callFetchFloorMaterialPriceSaga';
import callFetchWindowMaterialPriceSaga from './callFetchWndowMaterialPriceSaga';
import callFetchCurtainMaterialPriceSaga from './callFetchCurtainMaterialPriceSaga';
import calldeleteFloorImageSaga from './calldeleteFloorImageSaga';
import callUploadFloorImageSaga from './callUploadFloorImageSaga';
import callSendInviteClientSaga from './callInviteClientSaga';
import callSendInviteClientToStyleroomSaga from './callInviteClientToStyleroomSaga';

export default function* clientSagas() {
  yield takeLatest(clientActions.fetchAllClients, callFetchAllClientsSaga);
  yield takeLatest(clientActions.updateClientPortal, callUpdateClientPortalSaga);
  yield takeLatest(clientActions.updateClientTemplate, callUpdateClientTemplateSaga);
  yield takeLatest(clientActions.fetchAvailableTemplates, callFetchAvailableTemplatesSaga);
  yield takeLatest(clientActions.fetchAvailablePortalStatus, callFetchAvailablePortalStatusSaga);
  yield takeLatest(clientActions.fetchChosenStyle, callFetchChosenStyleSaga);
  yield takeLatest(clientActions.fetchMaterials, callFetchMaterialsSaga);
  yield takeEvery(clientActions.fetchMaterialColor, callFetchMaterialColorSaga);
  yield takeLatest(clientActions.updateChosenStyle, callUpdateChosenStyleSaga);
  yield takeLatest(clientActions.inviteAllClients, callInviteAllClientsSaga);
  yield takeLatest(clientActions.fetchExistingTemplates, callFetchExistingTemplatesSaga);
  yield takeLatest(clientActions.createQuoteInChangeStyle, callCreateQuoteSaga);
  yield takeLatest(clientActions.fetchAppointmentDetails, callFetchAppointmentDetailsSaga);
  yield takeLatest(clientActions.fetchMasterColorDropDown, callFetchMasterColorDropDownSaga);
  yield takeLatest(clientActions.fetchProductTypeDropDown, callFetchProductTypeDropDownSaga);
  yield takeLatest(clientActions.fetchClientQuote, callFetchClientQuoteSaga);
  yield takeLatest(clientActions.sendQuote, callSendClientQuoteSaga);
  yield takeLatest(clientActions.uploadEditTimeSlot, callUploadEditTimeSlotSaga);
  yield takeLatest(clientActions.deleteTimeSlot, callDeleteTimeSlotSaga);
  yield takeLatest(clientActions.postChosenStyles, callPostChosenStylesSaga);
  yield takeLatest(clientActions.fetchClientStatus, callFetchClientStatusSaga);
  yield takeLatest(clientActions.deleteTemplateWindow, callDeleteWindowSaga);
  yield takeLatest(clientActions.fetchFloorMaterialPrice, callFetchFloorMaterialPriceSaga);
  yield takeLatest(clientActions.fetchWindowMaterialPrice, callFetchWindowMaterialPriceSaga);
  yield takeLatest(clientActions.fetchCurtainMaterialPrice, callFetchCurtainMaterialPriceSaga);
  yield takeLatest(clientActions.deleteFloorImage, calldeleteFloorImageSaga);
  yield takeLatest(clientActions.uploadFloorImage, callUploadFloorImageSaga);
  yield takeLatest(clientActions.inviteClient, callSendInviteClientSaga);
  yield takeLatest(clientActions.inviteClientToStyleroom, callSendInviteClientToStyleroomSaga);
}
