import { call, put } from 'redux-saga/effects';
import { ClientResponse, FetchAllClientsProps } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';

//this needs to be called to set clients
async function callApi(props: FetchAllClientsProps) {
  const queryParam = {
    searchKey: props?.searchKey,
    lotTypeId: props?.lotTypeId,
    lots: props?.lots,
    clients: props?.clients,
    projectname: props?.projectname,
    status: props?.status,
    floors: props?.floors,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getClient, undefined, queryParam);
}

export default function* callFetchAllClientsSaga({ payload }: PayloadAction<FetchAllClientsProps>) {
  try {
    const allClients: ClientResponse = yield call(callApi, payload);
    yield put(clientActions.fetchAllClientsSuccess(allClients));
  } catch (error: unknown) {
    console.error('callFetchAllClientsSaga', error);
    yield put(clientActions.fetchAllClientsFailure());
  }
}
