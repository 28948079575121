import CustomDialog from '@shared/components/modal/CustomDialog';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import {
  chosenStyleSelector,
  editChosenStyleIsLoadingSelector,
  editChosenStyleIsSuccessSelector,
  postChosenStylesIsSuccessSelector,
  productTypeDropDownDataSelector,
} from '../store/selectors/clientSelector';
import Tabs from '@mui/material/Tabs';
import getReadableFloorName from '../../../utils/getReadableFloorName';
import Tab from '@mui/material/Tab';
import { clientActions } from '../store/slice/clientSlice';
import {
  GetProductDropDownParam,
  PostChosenStylesParams,
  ProductTypeDropDown,
  SelectedMaterial,
  UpdateChosenStyle,
  UpdateChosenStyles,
} from '../types';
import ChangeStyleModalContent from './ChangeStyleModalContent';
import Box from '@mui/material/Box';

function numberConversion(num: number | string) {
  if (num) {
    return parseInt(num.toString());
  }
  return -1;
}

interface ChangeStyleModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedClientId: number;
  floors: number;
  idTemplete: number;
  idDefaultTemplete: number;
  idLots: number;
  setHasToCreateQuote: () => void;
}

export default function ChangeStyleModal(props: ChangeStyleModalProps) {
  const {
    openModal,
    closeModal,
    idLots,
    selectedClientId,
    floors,
    idTemplete,
    idDefaultTemplete,
    setHasToCreateQuote,
  } = props;
  const dispatch = useAppDispatch();
  const editChosenStyleIsLoading = useAppSelector(editChosenStyleIsLoadingSelector);
  const editChosenStyleIsSuccess = useAppSelector(editChosenStyleIsSuccessSelector);
  const postChosenStylesIsSuccess = useAppSelector(postChosenStylesIsSuccessSelector);
  const chosenStyle = useAppSelector(chosenStyleSelector);
  const selectedMaterials = chosenStyle?.selectedMaterials ?? [];
  const productTypeDropDownData = useAppSelector(productTypeDropDownDataSelector);

  const [selectedFloor, setSelectedFloor] = useState(0);

  function handleChangeSelectedFloor(event: React.SyntheticEvent, newValue: number) {
    setSelectedFloor(newValue);
  }

  useEffect(() => {
    if (editChosenStyleIsSuccess || postChosenStylesIsSuccess) {
      closeModal();
      dispatch(clientActions.fetchChosenStyle(selectedClientId));
      dispatch(clientActions.resetUpdateChosenStyle());
      dispatch(clientActions.resetPostChosenStyles());
      dispatch(clientActions.resetProductTypeDropDown());
      setHasToCreateQuote();
      setSelectedFloor(0);
    }
  }, [
    closeModal,
    dispatch,
    editChosenStyleIsSuccess,
    selectedClientId,
    postChosenStylesIsSuccess,
    setHasToCreateQuote,
  ]);

  useEffect(() => {
    if (openModal) {
      const param: GetProductDropDownParam = {
        Clientid: selectedClientId,
        Floor: selectedFloor + 1,
        idLots: idLots,
      };
      dispatch(clientActions.fetchProductTypeDropDown(param));
    }
  }, [openModal, dispatch, selectedClientId, selectedFloor, idLots]);

  if (!openModal) return null;

  function handleSubmit(values: UpdateChosenStyles) {
    // modify payload
    const modifiedValues: UpdateChosenStyle[] = values.updateChosenStyles
      .filter((i) => Number(i.idProductType) !== -1)
      .map((i) => {
        const customCode = productTypeDropDownData.find(
          (j: ProductTypeDropDown) => j.idProductType === Number(i.idProductType)
        )?.idMaterialGroup;
        if (customCode) {
          i.idProductGroup = customCode;
        }
        return i;
      });
    if (selectedMaterials?.length !== 0) {
      const payload: UpdateChosenStyles = {
        clientId: values.clientId,
        updateChosenStyles: modifiedValues,
      };
      dispatch(clientActions.updateChosenStyle(payload));
    } else {
      const payload: PostChosenStylesParams = {
        idTemplete: idTemplete,
        idDefaultTemplete: idDefaultTemplete,
        values: values,
      };
      dispatch(clientActions.postChosenStyles(payload));
    }
  }

  const updateChosenStyles = [...Array(floors).keys()].reduce((acc: UpdateChosenStyle[], i) => {
    // -----------------floor-------------------------
    const floorGroupChosenStyle = selectedMaterials.find(
      (j: SelectedMaterial) => j.floors === i + 1 && j.productGroupCode === 1
    );
    if (floorGroupChosenStyle) {
      acc.push({
        idChoosenStyle: Number(floorGroupChosenStyle.idChoosenStyle),
        idProductType: Number(floorGroupChosenStyle.idProductType),
        idMaterialColor: Number(floorGroupChosenStyle.idMaterialColor),
        idProductGroup: Number(floorGroupChosenStyle.idProductGroup),
        price: Number(floorGroupChosenStyle.price),
        floors: i + 1,
        referId: 1,
      });
    } else {
      acc.push({
        idChoosenStyle: null,
        idProductType: -1,
        idMaterialColor: -1,
        idProductGroup: 1,
        price: 0,
        floors: i + 1,
        referId: 1,
      });
    }

    // ------------------windowDecoration------------------------
    const windowGroupChosenStyle = selectedMaterials.find(
      (j: SelectedMaterial) => j.floors === i + 1 && j.productGroupCode === 2
    );
    if (windowGroupChosenStyle) {
      acc.push({
        idChoosenStyle: Number(windowGroupChosenStyle.idChoosenStyle),
        idProductType: Number(windowGroupChosenStyle.idProductType),
        idMaterialColor: Number(windowGroupChosenStyle.idMaterialColor),
        idProductGroup: Number(windowGroupChosenStyle.idProductGroup),
        price: Number(windowGroupChosenStyle.price),
        floors: i + 1,
        referId: 999,
      });
    } else {
      acc.push({
        idChoosenStyle: null,
        idProductType: -1,
        idMaterialColor: -1,
        idProductGroup: 999,
        price: 0,
        floors: i + 1,
        referId: 999,
      });
    }

    // ------------------curtainDecoration------------------------
    const curtainGroupChosenStyle = selectedMaterials.find(
      (j: SelectedMaterial) => j.floors === i + 1 && j.productGroupCode === 3
    );
    if (curtainGroupChosenStyle) {
      acc.push({
        idChoosenStyle: Number(curtainGroupChosenStyle.idChoosenStyle),
        idProductType: Number(curtainGroupChosenStyle.idProductType),
        idMaterialColor: Number(curtainGroupChosenStyle.idMaterialColor),
        idProductGroup: Number(curtainGroupChosenStyle.idProductGroup),
        price: Number(curtainGroupChosenStyle.price),
        floors: i + 1,
        referId: 888,
      });
    } else {
      acc.push({
        idChoosenStyle: null,
        idProductType: -1,
        idMaterialColor: -1,
        idProductGroup: 888,
        price: 0,
        floors: i + 1,
        referId: 888,
      });
    }

    return acc;
  }, []);

  const initialValues: UpdateChosenStyles = {
    clientId: selectedClientId,
    updateChosenStyles: updateChosenStyles,
  };

  const validationSchema = Yup.object().shape({
    // portalStatus: Yup.string().required(),
    // noOfFloors: Yup.number().integer().min(1).required(),
  });

  function handleCloseModal() {
    setSelectedFloor(0);
    dispatch(clientActions.resetProductTypeDropDown());
    dispatch(clientActions.resetMaterialPrices());
    closeModal();
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm, resetForm }) => {
        const getFloorIndex = values.updateChosenStyles.findIndex(
          (i) => i.floors === selectedFloor + 1 && i.referId === 1
        );
        const getWindowIndex = values.updateChosenStyles.findIndex(
          (i) => i.floors === selectedFloor + 1 && i.referId === 999
        );
        const getCurtainIndex = values.updateChosenStyles.findIndex(
          (i) => i.floors === selectedFloor + 1 && i.referId === 888
        );

        const isValidSelection = values.updateChosenStyles.reduce((acc, i, index) => {
          const prevProductType = updateChosenStyles[index].idProductType;
          const prevMaterialColor = updateChosenStyles[index].idMaterialColor;
          if (
            numberConversion(i.idProductType) !== prevProductType &&
            numberConversion(i.idMaterialColor) === prevMaterialColor
          ) {
            acc = acc && false;
          }
          if (numberConversion(i.idProductType) === -1 && numberConversion(i.idMaterialColor) === -1) {
            acc = acc && true;
          }
          if (numberConversion(i.idProductType) === -1 && numberConversion(i.idMaterialColor) !== -1) {
            acc = acc && false;
          }
          if (numberConversion(i.idProductType) !== -1 && numberConversion(i.idMaterialColor) === -1) {
            acc = acc && false;
          }
          if (numberConversion(i.idProductType) !== -1 && numberConversion(i.idMaterialColor) !== -1) {
            acc = acc && true;
          }
          return acc;
        }, true);

        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={handleCloseModal}
              title={selectedMaterials?.length !== 0 ? 'Stijl bewerken' : 'Stijl instellen'}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={handleCloseModal}
              primaryButtonText={'opslaan en prijs berekenen'}
              primaryButtonDisabled={!isValid || !dirty || editChosenStyleIsLoading || !isValidSelection}
              handlePrimaryButtonPress={submitForm}
              width={selectedMaterials.length !== 0 ? '631px' : '500px'}
            >
              <Box
                mb={2}
                sx={{
                  marginLeft: '-40px',
                  marginRight: '-40px',
                }}
              >
                <Tabs
                  variant={'scrollable'}
                  scrollButtons
                  value={selectedFloor}
                  onChange={handleChangeSelectedFloor}
                  TabIndicatorProps={{ style: { height: 4 } }}
                  sx={{
                    '.MuiTabs-flexContainer': {
                      display: 'flex',
                      gap: '5px',
                    },
                    '.MuiTabs-scrollButtons': {
                      opacity: 0.4,
                    },
                    '.MuiTabs-scrollButtons.Mui-disabled': {
                      opacity: 0,
                    },
                  }}
                >
                  {getReadableFloorName(floors).map((el) => (
                    <Tab
                      key={el}
                      label={el}
                      sx={{
                        fontWeight: 700,
                        fontStyle: 'italic',
                        minHeight: '48px',
                        paddingLeft: 0,
                        paddingRight: '4px',
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              <ChangeStyleModalContent
                floorProductTypeField={`updateChosenStyles[${getFloorIndex}].idProductType`}
                floorMaterialColorField={`updateChosenStyles[${getFloorIndex}].idMaterialColor`}
                floorMaterialPrice={`updateChosenStyles[${getFloorIndex}].price`}
                windowProductTypeField={`updateChosenStyles[${getWindowIndex}].idProductType`}
                windowMaterialColorField={`updateChosenStyles[${getWindowIndex}].idMaterialColor`}
                windowMaterialPrice={`updateChosenStyles[${getWindowIndex}].price`}
                curtainProductTypeField={`updateChosenStyles[${getCurtainIndex}].idProductType`}
                curtainMaterialColorField={`updateChosenStyles[${getCurtainIndex}].idMaterialColor`}
                curtainMaterialPrice={`updateChosenStyles[${getCurtainIndex}].price`}
                isEditStyle={selectedMaterials.length !== 0}
                currentFloor={selectedFloor + 1}
                clientId={selectedClientId}
                idDefaultTemplate={idDefaultTemplete}
              />
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
