import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppointmentResp,
  AvailablePortalStatus,
  AvailableTemplate,
  ChosenStyle,
  ClientResponse,
  ClientState,
  CreateQuote,
  DeleteWindowParam,
  ExistingTemplateResponse,
  FetchAllClientsProps,
  FetchExistingTemplateParams,
  FetchMaterialColorPayload,
  GetProductDropDownParam,
  GetQuoteType,
  MasterColorDropDown,
  Material,
  MaterialPriceParam,
  PostChosenStylesParams,
  PostEmailAttachmentType,
  PostInviteClient,
  PostTimeSlot,
  PostTimeSlotResp,
  ProductTypeDropDown,
  UpdateChosenStyles,
  UpdateClientPortal,
  UpdateClientTemplatePayload,
  UploadFloorImageRequest,
  PostInviteClientToStyleroom,
} from '../../types';
import removeDuplicates from '../../utils/removeDuplicates';

const initialState: ClientState = {
  fetchAllClientIsLoading: false,
  clients: {
    totalPages: 0,
    count: 0,
    data: [],
  },
  fetchAllClientHasError: false,

  availablePortalStatusIsLoading: false,
  availablePortalStatus: [],
  availablePortalStatusHasError: false,

  updateClientPortalIsLoading: false,
  updateClientPortalIsSuccess: false,
  updateClientPortalHasError: false,

  availableTemplatesIsLoading: false,
  availableTemplates: [],
  availableTemplatesHasError: false,

  updateClientTemplateIsLoading: false,
  updateClientTemplateIsSuccess: false,
  updateClientTemplateHasError: false,

  chosenStyleIsLoading: false,
  chosenStyle: null,
  chosenStyleHasError: false,

  materialsIsLoading: false,
  materials: [],
  materialsHasError: false,

  materialColorIsLoading: false,

  editChosenStyleIsLoading: false,
  editChosenStyleIsSuccess: false,
  editChosenStyleHasError: false,

  fetchExistingTemplatesIsLoading: false,
  existingTemplateData: null,

  createQuoteIsLoading: false,
  createQuoteIsSuccess: false,
  createQuoteHasError: false,

  fetchAppointmentDetailsLoading: false,
  appointmentDetails: null,
  fetchAppointmentDetailsHasError: false,

  masterColorDropDownLoading: false,
  masterColorDropDownData: [],
  masterColorDropDownHasError: false,

  productTypeDropDownLoading: false,
  productTyoeDropDownIsEmpty: false,
  productTypeDropDownData: [],
  productTypeDropDownHasError: false,

  getClientQuoteIsLoading: false,
  getClientQuote: null,

  sendQuoteIsLoading: false,
  sendQuoteIsSuccess: false,
  sendQuoteHasError: false,

  uploadEditTimeSlotIsLoading: false,
  uploadEditTimeSlotIsSuccess: false,
  uploadedTimeSlotAlreadyExist: null,
  uploadEditTimeSlotHasError: false,

  deleteTimeSlotIsLoading: false,
  deleteTimeSlotIsSuccess: false,
  deleteTimeSlotHasError: false,

  postChosenStylesIsLoading: false,
  postChosenStylesIsSuccess: false,
  postChosenStyleHasError: false,

  deleteTemplateWindowIsLoading: false,
  deleteTemplateWindowIsSuccess: false,

  selectTemplateStatusChanged: false,

  materialPriceIsLoading: false,
  floorMaterialPrice: null,
  windowMaterialPrice: null,
  curtainMaterialPrice: null,

  selectedLotTypeId: null,
  selectedProjectId: null,

  deleteFloorImageIsLoading: false,
  deleteFloorImageIsError: false,

  uploadFloorImageLoading: false,
  uploadFloorImageError: false,

  inviteAllClientsSuccess: false,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchAllClients(state, action: PayloadAction<FetchAllClientsProps>) {
      state.fetchAllClientIsLoading = true;
      state.fetchAllClientHasError = false;
    },
    fetchAllClientsSuccess(state, action: PayloadAction<ClientResponse>) {
      state.fetchAllClientIsLoading = false;
      state.clients.totalPages = action.payload.totalPages;
      state.clients.count = action.payload.count;
      state.clients.data = action.payload.clients;
    },
    fetchAllClientsFailure(state) {
      state.fetchAllClientIsLoading = false;
      state.fetchAllClientHasError = true;
    },

    fetchAvailablePortalStatus(state) {
      state.availablePortalStatusIsLoading = true;
      state.availablePortalStatusHasError = false;
    },
    fetchAvailablePortalStatusSuccess(state, action: PayloadAction<AvailablePortalStatus[]>) {
      state.availablePortalStatusIsLoading = false;
      state.availablePortalStatus = action.payload;
    },
    fetchAvailablePortalStatusFailure(state) {
      state.availablePortalStatusIsLoading = false;
      state.availablePortalStatusHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateClientPortal(state, action: PayloadAction<UpdateClientPortal>) {
      state.updateClientPortalIsLoading = true;
      state.updateClientPortalIsSuccess = false;
      state.updateClientPortalHasError = false;
    },
    updateClientPortalSuccess(state) {
      state.updateClientPortalIsLoading = false;
      state.updateClientPortalIsSuccess = true;
    },
    updateClientPortalFailure(state) {
      state.updateClientPortalIsLoading = false;
      state.updateClientPortalIsSuccess = false;
      state.updateClientPortalHasError = true;
    },
    resetUpdateClientPortal(state) {
      state.updateClientPortalIsLoading = false;
      state.updateClientPortalIsSuccess = false;
      state.updateClientPortalHasError = false;
    },

    fetchAvailableTemplates(state) {
      state.availableTemplatesIsLoading = true;
      state.availableTemplatesHasError = false;
    },
    fetchAvailableTemplatesSuccess(state, action: PayloadAction<AvailableTemplate[]>) {
      state.availableTemplatesIsLoading = false;
      state.availableTemplates = action.payload;
    },
    fetchAvailableTemplatesFailure(state) {
      state.availableTemplatesIsLoading = false;
      state.availableTemplatesHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateClientTemplate(state, action: PayloadAction<UpdateClientTemplatePayload>) {
      state.updateClientTemplateIsLoading = true;
      state.updateClientTemplateIsSuccess = false;
      state.updateClientTemplateHasError = false;
    },
    updateClientTemplateSuccess(state) {
      state.updateClientTemplateIsLoading = false;
      state.updateClientTemplateIsSuccess = true;
    },
    updateClientTemplateFailure(state) {
      state.updateClientTemplateIsLoading = false;
      state.updateClientTemplateHasError = true;
    },
    resetUpdateClientTemplate(state) {
      state.updateClientTemplateIsLoading = false;
      state.updateClientTemplateIsSuccess = false;
      state.updateClientTemplateHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchChosenStyle(state, action: PayloadAction<number>) {
      state.chosenStyleIsLoading = true;
      state.chosenStyleHasError = false;
    },
    fetchChosenStyleSuccess(state, action: PayloadAction<ChosenStyle>) {
      state.chosenStyleIsLoading = false;
      state.chosenStyle = action.payload;
    },
    fetchChosenStyleFailure(state) {
      state.chosenStyleIsLoading = false;
      state.chosenStyleHasError = true;
    },

    fetchMaterials(state) {
      state.materialsIsLoading = true;
      state.materialsHasError = false;
    },
    fetchMaterialsSuccess(state, action: PayloadAction<Material[]>) {
      state.materialsIsLoading = false;
      state.materials = action.payload;
    },
    fetchMaterialsFailure(state) {
      state.materialsIsLoading = false;
      state.materialsHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchMaterialColor(state, action: PayloadAction<FetchMaterialColorPayload>) {
      state.materialColorIsLoading = true;
    },
    fetchMaterialColorLoaded(state) {
      state.materialColorIsLoading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateChosenStyle(state, action: PayloadAction<UpdateChosenStyles>) {
      state.editChosenStyleIsLoading = true;
      state.editChosenStyleIsSuccess = false;
      state.editChosenStyleHasError = false;
    },
    updateChosenStyleSuccess(state) {
      state.editChosenStyleIsLoading = false;
      state.editChosenStyleIsSuccess = true;
    },
    updateChosenStyleFailure(state) {
      state.editChosenStyleIsLoading = false;
      state.editChosenStyleHasError = true;
    },
    resetUpdateChosenStyle(state) {
      state.editChosenStyleIsLoading = false;
      state.editChosenStyleIsSuccess = false;
      state.editChosenStyleHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inviteAllClients(state) {
      state.inviteAllClientsSuccess = false;
    },
    inviteAllClientsSuccess(state) {
      state.inviteAllClientsSuccess = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchExistingTemplates(state, action: PayloadAction<FetchExistingTemplateParams>) {
      state.fetchExistingTemplatesIsLoading = true;
    },
    fetchExistingTemplatesLoaded(state, action: PayloadAction<ExistingTemplateResponse>) {
      state.fetchExistingTemplatesIsLoading = false;
      state.existingTemplateData = {
        ...state.existingTemplateData,
        [action.payload.floor]: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createQuoteInChangeStyle(state, action: PayloadAction<CreateQuote>) {
      state.createQuoteIsLoading = true;
      state.createQuoteIsSuccess = false;
      state.createQuoteHasError = false;
    },
    createQuoteInChangeStyleSuccess(state) {
      state.createQuoteIsLoading = false;
      state.createQuoteIsSuccess = true;
    },
    createQuoteInChangeStyleFailure(state) {
      state.createQuoteIsLoading = false;
      state.createQuoteHasError = true;
    },
    resetCreateQuoteInChangeStyle(state) {
      state.createQuoteIsLoading = false;
      state.createQuoteIsSuccess = false;
      state.createQuoteHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchAppointmentDetails(state, action: PayloadAction<number>) {
      state.fetchAppointmentDetailsLoading = true;
      state.fetchAppointmentDetailsHasError = false;
    },
    fetchAppointmentDetailsSuccess(state, action: PayloadAction<AppointmentResp>) {
      state.fetchAppointmentDetailsLoading = false;
      state.appointmentDetails = action.payload;
    },
    fetchAppointmentDetailsFailure(state) {
      state.fetchAppointmentDetailsLoading = false;
      state.fetchAppointmentDetailsHasError = true;
    },

    fetchMasterColorDropDown(state) {
      state.masterColorDropDownLoading = true;
      state.masterColorDropDownHasError = false;
    },
    fetchMasterColorDropDownSuccess(state, action: PayloadAction<MasterColorDropDown[]>) {
      state.masterColorDropDownLoading = false;
      state.masterColorDropDownData = action.payload;
      state.masterColorDropDownHasError = false;
    },
    fetchMasterColorDropDownFailure(state) {
      state.masterColorDropDownLoading = false;
      state.masterColorDropDownHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchProductTypeDropDown(state, action: PayloadAction<GetProductDropDownParam>) {
      state.productTypeDropDownLoading = true;
      state.productTypeDropDownHasError = false;
    },
    fetchProductTypeDropDownSuccess(state, action: PayloadAction<ProductTypeDropDown[]>) {
      state.productTypeDropDownLoading = false;
      if (action.payload.length === 0) {
        state.productTyoeDropDownIsEmpty = true;
      } else {
        state.productTyoeDropDownIsEmpty = false;
      }
      const preValues = state.productTypeDropDownData;
      const combined = [...preValues, ...action.payload];
      const actualDropDownValues = removeDuplicates(combined);
      state.productTypeDropDownData = actualDropDownValues;
      state.productTypeDropDownHasError = false;
    },
    fetchProductTypeDropDownFailure(state) {
      state.productTypeDropDownLoading = false;
      state.productTypeDropDownHasError = true;
    },
    resetProductTypeDropDown(state) {
      state.productTypeDropDownData = [];
    },
    fetchClientQuote(state, action: PayloadAction<number>) {
      state.getClientQuoteIsLoading = true;
    },
    fetchClientQuoteIsSuccess(state, action: PayloadAction<GetQuoteType>) {
      state.getClientQuoteIsLoading = false;
      state.getClientQuote = action.payload;
    },
    fetchClientQuoteFailure(state) {
      state.getClientQuoteIsLoading = false;
      state.getClientQuote = null;
    },
    sendQuote(state, action: PayloadAction<PostEmailAttachmentType>) {
      state.sendQuoteIsLoading = true;
      state.sendQuoteIsSuccess = false;
      state.sendQuoteHasError = false;
    },
    sendQuoteSuccess(state) {
      state.sendQuoteIsLoading = false;
      state.sendQuoteIsSuccess = true;
    },
    sendQuoteFailure(state) {
      state.sendQuoteIsLoading = false;
      state.sendQuoteHasError = true;
    },
    resetSendQuote(state) {
      state.sendQuoteIsLoading = false;
      state.sendQuoteIsSuccess = false;
      state.sendQuoteHasError = false;
    },
    uploadEditTimeSlot(state, action: PayloadAction<PostTimeSlot>) {
      state.uploadEditTimeSlotIsLoading = true;
      state.uploadEditTimeSlotIsSuccess = false;
      state.uploadedTimeSlotAlreadyExist = null;
      state.uploadEditTimeSlotHasError = false;
    },
    uploadEditTimeSlotSuccess(state, action: PayloadAction<PostTimeSlotResp>) {
      state.uploadEditTimeSlotIsLoading = false;
      if (action.payload.isSucess) {
        state.uploadEditTimeSlotIsSuccess = true;
      } else {
        state.uploadedTimeSlotAlreadyExist = action.payload.message;
      }
    },
    uploadEditTimeSlotFailure(state) {
      state.uploadEditTimeSlotIsLoading = false;
      state.uploadEditTimeSlotHasError = true;
    },
    resetUploadEditTimeSlot(state) {
      state.uploadEditTimeSlotIsLoading = false;
      state.uploadEditTimeSlotIsSuccess = false;
      state.uploadedTimeSlotAlreadyExist = null;
      state.uploadEditTimeSlotHasError = false;
    },
    deleteTimeSlot(state, action: PayloadAction<number>) {
      state.deleteTimeSlotIsLoading = true;
      state.deleteTimeSlotIsSuccess = false;
      state.deleteTimeSlotHasError = false;
    },
    deleteTimeSlotSuccess(state) {
      state.deleteTimeSlotIsLoading = false;
      state.deleteTimeSlotIsSuccess = true;
    },
    deleteTimeSlotFailure(state) {
      state.deleteTimeSlotIsLoading = false;
      state.deleteTimeSlotHasError = true;
    },
    resetDeleteTimeSlot(state) {
      state.deleteTimeSlotIsLoading = false;
      state.deleteTimeSlotIsSuccess = false;
      state.deleteTimeSlotHasError = false;
    },
    postChosenStyles(state, action: PayloadAction<PostChosenStylesParams>) {
      state.postChosenStylesIsLoading = true;
      state.postChosenStylesIsSuccess = false;
      state.postChosenStyleHasError = false;
    },
    postChosenStylesIsSuccess(state) {
      state.postChosenStylesIsLoading = false;
      state.postChosenStylesIsSuccess = true;
    },
    postChosenStylesFailure(state) {
      state.postChosenStylesIsLoading = false;
      state.postChosenStyleHasError = true;
    },
    resetPostChosenStyles(state) {
      state.postChosenStylesIsLoading = false;
      state.postChosenStylesIsSuccess = false;
      state.postChosenStyleHasError = false;
    },
    fetchClientStatus() {},
    deleteTemplateWindow(state, action: PayloadAction<DeleteWindowParam>) {
      state.deleteTemplateWindowIsLoading = true;
      state.deleteTemplateWindowIsSuccess = false;
    },
    deleteTemplateWindowSuccess(state) {
      state.deleteTemplateWindowIsLoading = false;
      state.deleteTemplateWindowIsSuccess = true;
    },
    deleteTemplateWindowFailure(state) {
      state.deleteTemplateWindowIsLoading = false;
      state.deleteTemplateWindowIsSuccess = false;
    },
    resetDeleteTemplateWindow(state) {
      state.deleteTemplateWindowIsLoading = false;
      state.deleteTemplateWindowIsSuccess = false;
    },
    setSelectTemplateStatusChanged(state) {
      state.selectTemplateStatusChanged = true;
    },
    resetSelectTemplateStatusChanged(state) {
      state.selectTemplateStatusChanged = false;
    },
    fetchFloorMaterialPrice(state, action: PayloadAction<MaterialPriceParam>) {
      state.materialPriceIsLoading = true;
    },
    fetchFloorMaterialPriceSuccess(state, action: PayloadAction<{ price: number }>) {
      state.floorMaterialPrice = action.payload.price;
    },
    fetchWindowMaterialPrice(state, action: PayloadAction<MaterialPriceParam>) {
      state.materialPriceIsLoading = true;
    },
    fetchWindowMaterialPriceSuccess(state, action: PayloadAction<{ price: number }>) {
      state.windowMaterialPrice = action.payload.price;
    },
    fetchCurtainMaterialPrice(state, action: PayloadAction<MaterialPriceParam>) {
      state.materialPriceIsLoading = true;
    },
    fetchCurtainMaterialPriceSuccess(state, action: PayloadAction<{ price: number }>) {
      state.curtainMaterialPrice = action.payload.price;
    },
    resetMaterialPrices(state) {
      state.floorMaterialPrice = null;
      state.windowMaterialPrice = null;
      state.curtainMaterialPrice = null;
    },
    fetchMaterialPriceIsLoaded(state) {
      state.materialPriceIsLoading = false;
    },
    setSelectedLotTypeId(state, action: PayloadAction<{ lotTypeId: number; projectId: number } | null>) {
      if (action.payload) {
        state.selectedLotTypeId = action.payload.lotTypeId;
        state.selectedProjectId = action.payload.projectId;
      } else {
        state.selectedLotTypeId = null;
        state.selectedProjectId = null;
      }
    },
    deleteFloorImage(state, action: PayloadAction<string>) {
      state.deleteFloorImageIsLoading = true;
      state.deleteFloorImageIsError = false;
    },
    deleteFloorImageSuccess(state) {
      state.deleteFloorImageIsLoading = false;
    },
    deleteFloorImageFailure(state) {
      state.deleteFloorImageIsLoading = false;
      state.deleteFloorImageIsError = true;
    },
    uploadFloorImage(state, action: PayloadAction<UploadFloorImageRequest>) {
      state.uploadFloorImageLoading = true;
      state.uploadFloorImageError = false;
    },
    uploadFloorImageSuccess(state) {
      state.uploadFloorImageLoading = false;
    },
    uploadFloorImageFailure(state) {
      state.uploadFloorImageLoading = false;
      state.uploadFloorImageError = true;
    },
    inviteClient(state, action: PayloadAction<PostInviteClient>) {
      state.inviteClientLoading = true;
      state.inviteClientError = false;
    },
    inviteClientFailure(state) {
      state.inviteClientLoading = false;
      state.inviteClientError = true;
    },
    inviteClientsSuccess(state) {
      state.inviteClientsSuccess = true;
    },

    inviteClientToStyleroom(state, action: PayloadAction<PostInviteClientToStyleroom>) {
      state.inviteClientToStyleroomLoading = true;
      state.inviteClientToStyleroomError = false;
    },
    inviteClientToStylroomFailure(state) {
      state.inviteClientToStyleroomLoading = false;
      state.inviteClientToStyleroomError = true;
    },
    inviteClientToStyleroomSuccess(state) {
      state.inviteClientToStyleroomSuccess = true;
    },
  },
});

// Actions
export const clientActions = clientSlice.actions;

// Reducer
const clientReducer = clientSlice.reducer;
export default clientReducer;
