import { call, put } from 'redux-saga/effects';
import { PostInviteClient } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(props: PostInviteClient) {
  const pathParam = {
    id: props.id,
  };

  return ApiRequest(Method.POST, ENDPOINTS.getInviteClient, pathParam, undefined, undefined);
}

export default function* callSendInviteClientSaga({ payload }: PayloadAction<PostInviteClient>) {
  try {
    const postResponse: any = yield call(callApi, payload);
    yield put(clientActions.inviteClientsSuccess());
    if (postResponse?.isMailSent) {
      SuccessToast('Succes!', 'E-mail verstuurd');
    }
  } catch (error: unknown) {
    console.error('callSendInviteClientSaga', error);
    yield put(clientActions.inviteClientFailure());
    ErrorToast(undefined, error.toString());
  }
}
